<template>
  <div id="root">
    <div>
      <el-button type="primary" @click="showEdit=true,editData={type:0}">新增</el-button>
      <el-button type="danger" @click="batchDel">批量删除</el-button>
    </div>
    <el-table border size="small" height="100%" style="margin-top: 20px" :data="list"
              @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="50"/>
      <el-table-column width="100" prop="sort" label="排序号"/>
      <el-table-column width="150" label="类型">
        <template slot-scope="scope">
          {{ getTypeStr(scope.row.type) }}
        </template>
      </el-table-column>
      <el-table-column width="100" prop="remark" label="备注"></el-table-column>
      <el-table-column label="内容">
        <template slot-scope="scope">
          <template v-if="scope.row.type==0">
            <div v-html="scope.row.content"></div>
          </template>

          <template v-if="scope.row.type==1">
            <img @click="showFullImageBox(scope.row.content)" style="max-height: 80px;max-width: 80px;cursor: pointer"
                 :src="getImageUrl(scope.row.content)">
          </template>

          <template v-if="scope.row.type==2">
            <u style="color: #53a0e7;cursor: pointer"
               @click="downloadFile(scope.row.content)">{{ getFileInfo(scope.row.content) }}</u>
          </template>

          <template v-if="scope.row.type==3">
            <audio controls :src="getAudioSrc(scope.row.content)">播放</audio>
          </template>


          <div @click="showFullVideoBox(scope.row.content)" v-if="scope.row.type==4"
               style="position: relative;max-height: 80px;max-width: 80px;cursor: pointer">
            <i class="el-icon-video-play"
               style="position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);font-size: 20px;"/>
            <img style="max-width: 80px;max-height: 80px;" :src="getImageUrl(scope.row.content)">
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="warning" @click="edit(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="deleteData(scope.row)">删除</el-button>
          <el-button size="mini" type="success" @click="sendData(scope.row)">发送</el-button>
        </template>
      </el-table-column>
    </el-table>


    <el-dialog width="800px" :title="editData.id?'编辑':'新增'" :visible.sync="showEdit" :close-on-click-modal="false"
               :append-to-body="true">
      <div>
        <el-row :gutter="20" align="middle" type="flex">
          <el-col :span="2">类型</el-col>
          <el-col :span="22">
            <el-select size="small" v-model="editData.type" value-key="type" style="width: 100%;"
                       @change="editData.content=null">
              <el-option v-for="item in types" :label="item.label" :value="item.type"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row :gutter="20" align="middle" type="flex" style="margin-top: 20px">
          <el-col :span="2">排序号</el-col>
          <el-col :span="22">
            <el-input size="small" v-model="editData.sort" type="number"></el-input>
          </el-col>
        </el-row>
        <el-row :gutter="20" align="middle" type="flex" style="margin-top: 20px">
          <el-col :span="2">备注</el-col>
          <el-col :span="22">
            <el-input size="small" v-model="editData.remark"></el-input>
          </el-col>
        </el-row>
        <!--        文本-->
        <el-row v-if="editData.type==$enums.MESSAGE_TYPE.TEXT" :gutter="20" align="middle" type="flex"
                style="margin-top: 20px;align-items: flex-start">
          <el-col :span="2">内容</el-col>
          <el-col :span="22">
            <rich-editor v-model="editData.content"></rich-editor>
          </el-col>
        </el-row>
        <!--        图片-->
        <el-row v-if="editData.type==$enums.MESSAGE_TYPE.IMAGE" :gutter="20" align="middle" type="flex"
                style="margin-top: 20px;align-items: flex-start">
          <el-col :span="2">图片</el-col>
          <el-col :span="22" style="text-align: left">
            <file-upload :action="'/image/upload'" :maxSize="5 * 1024 * 1024"
                         :fileTypes="['image/jpeg', 'image/png', 'image/jpg', 'image/webp', 'image/gif']"
                         @success="onImageSuccess" @fail="onImageFail">
              <img v-if="editData.content" :src="getImageUrl(editData.content)"
                   style="max-width: 300px;max-height: 300px"/>
              <el-button v-else size="mini" type="primary">点击上传</el-button>
            </file-upload>
          </el-col>
        </el-row>
        <!--        语音-->
        <el-row v-if="editData.type==$enums.MESSAGE_TYPE.AUDIO" :gutter="20" align="middle" type="flex"
                style="margin-top: 20px;align-items: flex-start">
          <el-col :span="2">语音</el-col>
          <el-col :span="22">
            <div style="text-align: left">
              <template v-if="editData.content">
                <audio controls :src="getAudioSrc(editData.content)"></audio>
                <el-button size="mini" type="primary" @click="showRecord=true">重新录音</el-button>
              </template>
              <template v-else>
                <el-button size="mini" type="primary" @click="showRecord=true">录音上传</el-button>
              </template>
            </div>
          </el-col>
        </el-row>
        <!--        视频-->
        <el-row v-if="editData.type==$enums.MESSAGE_TYPE.VIDEO" :gutter="20" align="middle" type="flex"
                style="margin-top: 20px;align-items: flex-start">
          <el-col :span="2">视频</el-col>
          <el-col :span="22" style="text-align: left">
            <file-upload :action="'/video/upload'" :maxSize="5 * 1024 * 1024"
                         :fileTypes="['video/mp4']"
                         @success="onImageSuccess" @fail="onImageFail">
              <img v-if="editData.content" :src="getImageUrl(editData.content)"
                   style="max-width: 300px;max-height: 300px"/>
              <el-button v-else size="mini" type="primary">点击上传</el-button>
            </file-upload>
          </el-col>
        </el-row>
        <!--        文件-->
        <el-row v-if="editData.type==$enums.MESSAGE_TYPE.FILE" :gutter="20" align="middle" type="flex"
                style="margin-top: 20px;align-items: flex-start">
          <el-col :span="2">文件</el-col>
          <el-col :span="22">
            <file-upload :action="'/file/upload'" :maxSize="5 * 1024 * 1024"
                         @success="onFileSuccess" @fail="onImageFail">
              <div v-if="editData.content">
                {{ getFileInfo(editData.content) }}
              </div>
              <el-button v-else size="mini" type="primary">点击上传</el-button>
            </file-upload>
          </el-col>
        </el-row>
      </div>
      <div slot="footer">
        <el-button size="mini" @click="showEdit=false">取消</el-button>
        <el-button size="mini" type="primary" @click="confirmEdit">确定</el-button>
      </div>
    </el-dialog>

    <chat-record :visible="showRecord" send-str="上传录音" @send="onAudioSend" @close="showRecord=false"></chat-record>
  </div>
</template>

<script>
import FileUpload from "../components/common/FileUpload.vue";
import ChatRecord from "../components/chat/ChatRecord";
import RichEditor from "../components/common/RichEditor";

export default {
  name: "Qrcode",
  data() {
    return {
      uid: null,
      selDatas: [],
      showRecord: false,
      types: [
        {
          label: "文字",
          type: this.$enums.MESSAGE_TYPE.TEXT,
        },
        {
          label: "图片",
          type: this.$enums.MESSAGE_TYPE.IMAGE
        },
        {
          label: "语音",
          type: this.$enums.MESSAGE_TYPE.AUDIO
        },
        {
          label: "视频",
          type: this.$enums.MESSAGE_TYPE.VIDEO
        },
        {
          label: "文件",
          type: this.$enums.MESSAGE_TYPE.FILE
        },
      ],
      editData: {},
      showEdit: false,
      list: [
        {
          id: 1,
          type: 1,
          status: 1,
          content: "{\"originUrl\":\"http://localhost:9000/box-im/image/20240907/1725678347871.jpg\",\"thumbUrl\":\"http://localhost:9000/box-im/image/20240907/1725678347953.jpg\"}"
        },
        {
          id: 2,
          type: 4,
          status: 1,
          content: "{\"originUrl\":\"http://localhost:9000/box-im/image/20240907/1725678338232.mp4\",\"thumbUrl\":\"http://localhost:9000/box-im/image/20240907/1725678338330.jpg\"}"
        },
        {
          id: 3,
          type: 3,
          status: 1,
          content: "{\"duration\":9,\"url\":\"http://localhost:9000/box-im/file/20240907/1725678330148.wav\"}"
        },
        {
          id: 4,
          type: 0,
          status: 1,
          content: "https://www.baidu.com"
        },
        {
          id: 5,
          type: 2,
          status: 0,
          content: "{\"name\":\"07-白象食品股份有限公司大华捷通COD+云MIS远程收款项目验收报告V1.1.docx\",\"size\":41278,\"url\":\"http://localhost:9000/box-im/file/20240907/1725678352900.docx\"}"
        },
      ]
    }
  },
  components: {
    RichEditor,
    ChatRecord,
    FileUpload
  },
  created() {
    this.list = []
    this.uid = this.$store.state.userStore.userInfo.id
    if (this.uid) {
      this.fillData()
    }
    this.$eventBus.$on("loadUserInfoSuccess", () => {
      this.fillData();
    });
  },
  methods: {
    async sendData(row) {
      await this.$confirm("是否确认群发本条消息给所有人？")
      const res = await this.$http.post("/grouptext/sendGroupText?id=" + row.id,{})
      if (!res){
        this.$alert("群发失败")
        return;
      }
      this.$message({
        type:"success",
        message:"群发成功"
      })
      this.$http.get("/message/private/pullOfflineMessage?minId=0")
    },
    async fillData() {
      const res = await this.$http.post("/grouptext/getList?uid=" + this.$store.state.userStore.userInfo.id, {})
      this.list = res;
    },
    handleSelectionChange(val) {
      this.selDatas = val;
    },
    batchDel() {
      if (this.selDatas == null || this.selDatas.length == 0) {
        this.$message({
          type: "error",
          message: "请先勾选需要操作的数据"
        })
        return;
      }
      this.$confirm(`是否确认删除选择的快捷回复？`).then(() => {
        this.$http.post("/grouptext/deleteData", {ids: this.selDatas.map(item => item.id)}).then(res => {
          if (res)
            this.fillData()
          else
            this.$message({
              type: "error",
              message: "删除失败"
            })
        })
      })
    },
    async confirmEdit() {
      if (!this.editData.content) {
        this.$message({
          type: "error",
          message: "内容不能为空"
        })
        return;
      }
      this.editData.uid = this.$store.state.userStore.userInfo.id
      const res = await this.$http.post(this.editData.id ? "/grouptext/update" : "/grouptext/add", this.editData)
      if (!res) {
        this.$message({
          type: "error",
          message: this.editData.id ? "更新失败" : "新增失败"
        })
        return;
      }
      this.showEdit = false;
      this.fillData()
    },
    edit(row) {
      this.editData = JSON.parse(JSON.stringify(row));
      this.showEdit = true;
    },
    getAudioSrc(data) {
      return JSON.parse(data).url;
    },
    deleteData(data) {
      this.$confirm("是否确认删除？").then(() => {
        this.$http.post("/grouptext/deleteData", {ids: [data.id]}).then(res => {
          if (res)
            this.fillData()
          else
            this.$message({
              type: "error",
              message: "删除失败"
            })
        })
      })
    },
    getTypeStr(type) {
      if (type == 0) {
        return "文字"
      }
      if (type == 1) {
        return "图片"
      }
      if (type == 2) {
        return "文件"
      }
      if (type == 3) {
        return "语音"
      }
      if (type == 4) {
        return "视频"
      }
    },
    showFullImageBox(data) {
      let imageUrl = JSON.parse(data).originUrl;
      if (imageUrl) {
        this.$store.commit('showFullImageBox', imageUrl);
      }
    },
    showFullVideoBox(data) {
      let originUrl = JSON.parse(data).originUrl;
      if (originUrl) {
        this.$store.commit('showFullVideoBox', originUrl);
      }
    },
    downloadFile(data) {
      const r = JSON.parse(data);
      window.location.href = r.url;
    },
    getImageUrl(data) {
      try {
        const r = JSON.parse(data);
        return r.thumbUrl
      } catch (e) {
        return ""
      }
    },
    onAudioSend(data) {
      console.log(data)
      this.editData.content = JSON.stringify(data);
      console.log(this.editData.content)
      this.$forceUpdate()
    },
    getFileInfo(data) {
      const r = JSON.parse(data);
      return `${r.name}   (${(r.size / 1024).toFixed(1)}KB)`
    },
    onImageSuccess(data, file) {
      this.editData.content = JSON.stringify(data)
      this.$forceUpdate()
    },
    onFileSuccess(data, file) {
      const fData = {
        name: file.name,
        size: file.size,
        url: data
      }
      this.editData.content = JSON.stringify(fData)
      this.$forceUpdate()
    },
    onImageFail() {
      this.$message({
        type: "error",
        message: "上传失败"
      })
    },
  }
}
</script>

<style scoped>
#root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  box-sizing: border-box;
  background: white;
  width: 100%;
  height: 100%;
}
</style>